import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { motion } from "framer-motion";
import GiftIcon from "@mui/icons-material/CardGiftcard";
import "../styles/RegistryInfo.css";

const RegistryInfo = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="registry-info-page"
    >
      <Container maxWidth="md" className="registry-container">
        <Typography variant="h2" className="registry-info-title">
          <GiftIcon className="title-icon" />
          Registry Information
        </Typography>

        <Box className="registry-message-box">
          <Typography className="registry-info-text">
            Kelly and Ben are waiting until mid-April for Ben to move before
            they start receiving wedding gifts. We are so excited to show our registry to you soon :)
          </Typography>
        </Box>
      </Container>
    </motion.div>
  );
};

export default RegistryInfo;
